<template>
  <InputComponent ref="input" :type="type" :value="value" :label="label" :placeholder="$t('app.type-password')"
    @input="handleInput" :required="required" :disabled="disabled" :rules="rules">
    <template #append>
      <button class="btn btn-icon" type="button" @click="toggle()">
        <span class="material-icons-outlined">
          <template v-if="type === 'password'">visibility</template>
          <template v-else>visibility_off</template>
        </span>
      </button>
    </template>
  </InputComponent>
</template>
<script>
import InputComponent from '@/components/form/InputComponent.vue';

export default {
  name: 'PasswordInputComponent',
  components: {
    InputComponent,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      type: 'password',
      checked: false,
      valid_length: false,
      contains_number: false,
      contains_lowercase: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      show: false,
    };
  },
  computed: {
    rules() {
      if (this.validate) {
        return ['min:13', 'upper', 'lower', 'number', 'special-char'];
      }
      return [];
    },
    isValid() {
      return this.$refs.input.isValid;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    checkEvent() {
      this.checked = true;
      console.log('Verificado');
    },
    checkPassword(e) {
      this.checked = true;
      this.value = e;
    },
    toggle() {
      this.type = this.type === 'password' ? 'text' : 'password';
      this.show = !this.show;
    },
    checkValidation() {
      this.$refs.input.checkValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
/*.eyes_btn {
  border-radius: 8px;
  background-color: var(--form-input);
  border-color: var(--form-border) !important;
  border-width: 1px !important;
  border-style: solid !important;
  font-size: 24px;
}*/
</style>
